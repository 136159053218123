<template>
	<div v-if="products.length" class="exhibitors-catalog__container">
		<template v-if="!showProgress && this.products.length > 0" style="width: 100%">
			<v-row class="row" no-gutters>
				<v-col v-for="item in products" :key="item.inteproductserviceid" md="3">
					<v-card class="mx-auto my-12 card" :outlined="true">
						<div style="position: relative">
							<div class="exhibitor-details-products__item-top-image" style="position: absolute">
								<router-link :to="{ name: 'ProductDetails', params: { id: item.inteproductserviceid } }">
									<img
										class="exhibitor-details-products__item-top-image-img"
										v-if="item.imageurl"
										:src="item.imageurl"
										alt="photo"
									/>
									<img class="exhibitor-details-products__item-top-image-img" v-else :src="defaultPhoto" alt="photo" />
								</router-link>
							</div>
							<div style="position: absolute; margin: 0.625rem 0 0 0.625rem">
								<span v-if="item.novetly" class="exhibitor-details-products__item-top-image-NEW">{{ $t('products.new') }}</span>
							</div>
							<div style="position: absolute; right: 0; margin-right: 0.625rem">
								<div class="exhibitor-details-products__item-top-image-buttons">
									<ShareDropdown
										:url="getShareURL(item.inteproductserviceid)"
										:exhibitorName="item.exhiname"
										:productName="item.pstrname"
										white
									/>
									<Modal :products="item" card interestFav />
								</div>
							</div>
						</div>
						<div class="bottom">
							<div class="text">
								<router-link
									:to="{ name: 'ProductDetails', params: { id: item.inteproductserviceid } }"
									style="text-decoration: none"
								>
									<v-card-title class="marginated titol">{{ item.pstrname }}</v-card-title>
								</router-link>
								<v-card-text class="marginated sectores">
									<!-- <div v-if="sectors[i] !== 'undefined'">{{ sectors[i] }}</div> -->
									<div v-if="item.sector">
										{{ item.sectorname }}
									</div>
									<!-- <div v-else class="exhibitors-catalog__body-row-sector-text marginated sectores"></div> -->
								</v-card-text>
							</div>
							<div>
								<v-card-actions class="actions">
									<v-img v-if="item.logourl" :src="item.logourl" class="imagen" alt="logo"></v-img>
									<v-img v-else :src="defaultPhoto" class="imagen" alt="logo"></v-img>
								</v-card-actions>
							</div>
						</div>
					</v-card>
				</v-col>
				<router-link :to="{ name: 'Products' }" class="interest-product-cards-search">
					<img class="interest-product-cards-search-img" :src="IconAdd" alt="add" />
					<div class="interest-product-cards-search-title">{{ $t('interests.searchProducts') }}</div>
				</router-link>
			</v-row>
		</template>
		<div v-else style="margin-left: 50%; margin-top: 5%; margin-bottom: 5%">
			<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
		</div>
	</div>
	<InterestEmpty v-else products />
</template>
<script>
import { getDocument } from '@/api/common.js';
import ShareDropdown from '@/components/common/ShareDropdown.vue';
import Modal from '@/components/common/Modal.vue';
import InterestEmpty from '@/components/interests/InterestEmpty.vue';

export default {
	name: 'InterestProductCards',

	props: {
		products: {}
	},
	data() {
		return {
			IconFav: require('@/assets/icons/star-white.svg'),
			IconShare: require('@/assets/icons/share-white.svg'),
			IconAdd: require('@/assets/icons/add.svg'),
			catalog: [],
			defaultPhoto: null,
			ascending: true,
			sortBy: 'name',
			current: 1,
			pageSize: 20,
			favBtnProductSelected: false,
			showProgress: false
		};
	},
	components: { ShareDropdown, Modal, InterestEmpty },
	created() {
		this.subscribeToEvents();
	},
	mounted() {
		this.getDefaultPhoto();
	},
	methods: {
		getDefaultPhoto: async function () {
			const data = await getDocument('event', this.$store.state.eventInfo.evenid, 'event_app_primary');
			this.defaultPhoto = data[0].url;
		},
		getShareURL: function (productId) {
			return window.location.origin + this.$router.resolve({ name: 'ProductDetails', params: { id: productId } }).href;
		},

		subscribeToEvents() {
			this.$puiEvents.$on('searching-true', () => {
				this.showProgress = true;
			});
			this.$puiEvents.$on('searching-false', () => {
				this.showProgress = false;
			});
		}
	},
	computed: {}
};
</script>

<style lang="scss" scoped>
.interest-product-cards-search {
	@media only screen and (max-width: 468px) {
		width: 100%;
	}
	@media only screen and (min-width: 468px) {
		width: calc(50% - 1.4em);
	}
	@media only screen and (min-width: 675px) {
		width: calc(33% - 1.4em);
	}
	@media only screen and (min-width: 844px) {
		width: calc(25% - 1.4em);
	}

	height: 18.75rem;
	border: 1px solid #d2d7d9;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	margin: 0.7em;

	&-title {
		padding-top: 1.25rem;
		display: table-caption;
		font-family: Cabin;
		font-weight: bold;
		font-size: 26px;
		text-align: center;
		word-spacing: 100vw;
		color: #293133;
	}
}

.bottom {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}
.text {
	padding-top: 9.5rem;
}
.router {
	text-decoration: none !important;
}
.card {
	border-radius: 8px;
	margin: 0.7em !important;
	height: 340px;
	display: flex;
	flex-direction: column;
}
.row {
	margin: 20px;
}
.contact {
	width: 18px;
	height: 20px;
}
.marginated {
	margin-left: 4px;
}
.sectores {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	padding-top: 6px !important;
	/* identical to box height, or 150% */
	// min-height: 60px;
	// max-height: 60px;
	/* Neutral / Dark grey */
	color: #575e60;
}
.titol {
	font-family: Cabin;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	// min-height: 80px;
	// max-height: 80px;
	padding-bottom: 0px !important;
	/* Neutral / Extra-dark grey */
	color: #293133;
}
.imagen {
	max-width: 71px;
	max-height: 40px;
	width: auto;
	height: auto;
	object-fit: contain;
}
.actions {
	min-height: 55px;
	max-height: 55px;
	margin-left: 10px;
	margin-bottom: 10px;
	margin-right: 10px;
}
.exhibitors-catalog {
	background-color: #f3f5f5;
	width: 100%;
	padding: 2.5rem;
}
.exhibitors-catalog__container {
	background-color: #fff;
	padding-top: 2%;
	padding-left: 12%;
	padding-right: 12%;
	padding-bottom: 2%;
}
.exhibitors-catalog__pagination {
	display: flex;
	justify-content: center;
	align-items: baseline;
	&-info {
		margin-right: 0.5rem;
	}
	&-button {
		padding: 0.5rem 0.75rem;
		margin: 0.125rem;
		border-radius: 6px;
		background-color: #f5f7f7;
	}
	&-select {
		width: 4rem;
		margin: 0 0.25rem;
	}
}
.exhibitor-details-products {
	padding-bottom: 2rem;
}
.exhibitor-details-products__top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	&-title {
		display: flex;
		padding-bottom: 0.812rem;
		&-text {
			padding-left: 0.75rem;
			font-family: Cabin;
			font-weight: bold;
			font-size: 26px;
			color: #293133;
		}
	}
	&-pagination {
		&-button {
			padding: 0 0.5rem;
		}
	}
}
.exhibitor-details-products__list {
	display: flex;
}
.exhibitor-details-products__item {
	border: 1px solid #d2d7d9;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0.313rem;
	width: 32%;
	height: 21rem;
	&-top {
		&-image {
			height: 9.5rem;
			width: 100%;
			&-img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 8px 8px 0 0;
			}
			&-NEW {
				color: #fff;
				background-color: #e36f05;
				border-radius: 6px;
				padding: 0.125rem 0.375rem;
				font-weight: 500;
				font-size: 0.75rem;
				height: 1.4rem;
				display: flex;
				align-items: center;
			}
			&-buttons {
				display: flex;
			}
		}
		&-text {
			padding: 10.5rem 0 1rem 1rem;
			padding-right: 1rem;
			&-title {
				font-family: Cabin;
				font-weight: bold;
				font-size: 16px;
				color: #293133;
				padding-bottom: 0.25rem;
				&-link {
					text-decoration: none;
				}
			}
			&-sector {
				font-weight: normal;
				font-size: 14px;
				color: #575e60;
			}
		}
	}
	&-bottom {
		width: 35%;
		& > img {
			width: 100%;
			max-height: 2.5rem;
			object-fit: cover;
			margin: 0 0 1rem 1rem;
		}
	}
}
</style>
