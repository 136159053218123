<template>
	<div class="interest-empty">
		<h2 class="interest-empty-title">{{ title }}</h2>
		<span class="interest-empty-subtitle"> {{ subtitle }} </span>
		<v-btn v-if="exhibitors" :to="{ name: 'Exhibitors' }" class="interest-empty-button" color="#2498FF" depressed> {{ button }}</v-btn>
		<v-btn v-else-if="products" :to="{ name: 'Products' }" class="interest-empty-button" color="#2498FF" depressed> {{ button }}</v-btn>
		<v-btn v-else-if="activities" :to="{ name: 'Activities' }" class="interest-empty-button" color="#2498FF" depressed> {{ button }}</v-btn>
		<v-btn v-else-if="contacts" :to="{ name: 'Contacts' }" class="interest-empty-button" color="#2498FF" depressed> {{ button }}</v-btn>
	</div>
</template>

<script>
export default {
	name: 'InterestEmpty',

	props: {
		exhibitors: Boolean,
		products: Boolean,
		activities: Boolean,
		contacts: Boolean
	},

	data() {
		return {
			title: null,
			subtitle: null,
			button: null
		};
	},

	mounted() {
		this.getInfo();
	},

	methods: {
		getInfo: function () {
			if (this.exhibitors) {
				this.title = this.$t('interests.empty.titleExhibitors');
				this.subtitle = this.$t('interests.empty.subTitleExhibitors');
				this.button = this.$t('interests.empty.buttonExhibitors');
			} else if (this.products) {
				this.title = this.$t('interests.empty.titleProducts');
				this.subtitle = this.$t('interests.empty.subTitleProducts');
				this.button = this.$t('interests.empty.buttonProducts');
			} else if (this.activities) {
				this.title = this.$t('interests.empty.titleActivities');
				this.subtitle = this.$t('interests.empty.subTitleActivities');
				this.button = this.$t('interests.empty.buttonActivities');
			} else if (this.contacts) {
				this.title = this.$t('interests.empty.titleContacts');
				this.subtitle = this.$t('interests.empty.subTitleContacts');
				this.button = this.$t('interests.empty.buttonContacts');
			}
		}
	},

	watch: {
		'$store.state.activeLanguage': 'getInfo'
	}
};
</script>


<style lang="scss" scoped>
.interest-empty {
	height: 80vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&-title {
		font-family: Cabin;
		font-weight: bold;
		font-size: 26px;
		color: #293133;
	}

	&-subtitle {
		font-size: 20px;
		color: #575e60;
	}

	&-button {
		margin-top: 1.5rem;
		color: #fff;
		border-radius: 8px !important;
	}
}
</style>
