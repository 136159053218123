<template>
	<div>
		<v-toolbar class="interests-tabs" :style="{ 'background-color': primaryColor }">
			<v-tabs v-model="tabs" dark>
				<v-tab>
					<v-badge color="white" :content="interestExhibitors.length ? interestExhibitors.length : '0'" inline>
						{{ $t('interests.tabs.exhibitors') }}
					</v-badge>
				</v-tab>
				<v-tab>
					<v-badge color="white" :content="interestsProducts.length ? interestsProducts.length : '0'" inline>
						{{ $t('interests.tabs.products') }}
					</v-badge>
				</v-tab>
				<!--<v-tab><v-badge color="white" content="5" inline>Actividades</v-badge></v-tab>
				<v-tab><v-badge color="white" content="2" inline>Contactos</v-badge></v-tab>-->
			</v-tabs>
		</v-toolbar>
		<v-tabs-items v-model="tabs">
			<v-tab-item> <InterestExhibitorCards :exhibitors="interestExhibitors" /> </v-tab-item>
			<v-tab-item> <InterestProductCards :products="interestsProducts" /> </v-tab-item>
			<!-- <v-tab-item><ActivitiesInterests :defaultPhoto="defaultPhoto" /></v-tab-item>
			<v-tab-item><h1>Contenido</h1></v-tab-item> -->
		</v-tabs-items>
	</div>
</template>


<script>
import { getDocument } from '@/api/common.js';
import { getProductsInterests } from '@/api/interest.js';
import { getExhibitorsInterests } from '@/api/interest.js';

import InterestProductCards from '@/components/interests/InterestProductCards.vue';
import InterestExhibitorCards from '@/components/interests/InterestExhibitorCards.vue';
//import ActivitiesInterests from '@/components/interests/ActivitiesInterests.vue';

import { search } from '@/api/common.js';

export default {
	name: 'InterestsTabs',
	props: {
		primaryColor: String,
		value: {
			type: [Object, Array]
		}
	},

	components: { InterestProductCards, InterestExhibitorCards /*ActivitiesInterests,*/ },

	data() {
		return {
			tabs: null,
			defaultPhoto: null,
			interestsProducts: [],
			interestExhibitors: [],
			products: {},
			exhibitors: {}
		};
	},
	mounted() {
		this.checkLoginAndGetInterests();
		this.getDefaultPhoto();
	},
	created() {
		this.searchExhibitors();
		this.$puiEvents.$on('reloadFav', () => {
			this.checkLoginAndGetInterests();
		});
	},
	destroyed() {
		this.$puiEvents.$off('reloadFav');
	},
	methods: {
		checkLoginAndGetInterests() {
			this.checkLoginData();
			if (this.isLogged) {
				// lo ponemos a false para que los cards no existan
				this.gotUserProductInterests = false;
				this.user = JSON.parse(localStorage.getItem('pui9_session'));
				this.getUserProductInterests();
				this.getUserExhibitorsInterests();
			}
		},

		getDefaultPhoto: async function () {
			const data = await getDocument('event', this.$store.state.eventInfo.evenid, 'event_app_primary');
			this.defaultPhoto = data[0].url;
		},

		checkLoginData() {
			if (localStorage.getItem('pui9_session') !== null) {
				this.isLogged = true;
			} else {
				this.isLogged = false;
			}
		},
		getUserProductInterests: async function () {
			if (this.isLogged) {
				var params = {
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								data: this.$store.state.eventInfo.evenid,
								field: 'exhieventid',
								op: 'eq'
							},

							{
								data: this.user.email, //CORREO DEL USUARIO
								field: 'intevisitorid',
								op: 'eq'
							}
						]
					},

					order: [
						{
							column: 'interest',
							direction: 'asc'
						}
					],
					page: 1,
					queryFields: ['interest'],
					queryText: '',
					rows: 999999
				};

				let data = await getProductsInterests(params);
				this.interestsProducts = data;
				this.addFav(this.interestsProducts);
				console.log(this.interestsProducts);
			}
		},
		getUserExhibitorsInterests: async function () {
			if (this.isLogged) {
				var params = {
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								data: this.$store.state.eventInfo.evenid,
								field: 'exhieventid',
								op: 'eq'
							},

							{
								data: this.user.email, //CORREO DEL USUARIO
								field: 'intevisitorid',
								op: 'eq'
							}
						]
					},

					order: [
						{
							column: 'interest',
							direction: 'asc'
						}
					],
					page: 1,
					queryFields: ['interest'],
					queryText: '',
					rows: 999999
				};

				let data = await getExhibitorsInterests(params);
				this.interestExhibitors = data;
				this.addFav(this.interestExhibitors);

				console.log(this.interestExhibitors);
			}
		},

		addFav(array) {
			array.forEach((element) => {
				element.fav = true;
			});
		},
		async searchExhibitors() {
			const body = {
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							data: this.$store.state.eventInfo.evenid,
							field: 'event',
							op: 'eq'
						}
					]
				},
				model: 'catalogexhibitor',
				order: [
					{
						column: 'name',
						direction: 'asc'
					}
				],
				page: 1,
				queryFields: ['name'],
				queryText: '',
				rows: 999999999
			};

			const data = await search(body);
			this.exhibitors = data.data;
		}
	},
	watch: {
		'$store.state.activeLanguage'(newVal) {
			if (newVal) {
				this.checkLoginAndGetInterests();
			}
		}
	}
};
</script>


<style lang="scss">
.interests-tabs {
	box-shadow: none !important;
	padding-left: 10%;

	& > .v-toolbar__content > .v-tabs > .v-item-group {
		& > .v-slide-group__prev {
			display: none !important;
		}

		& > .v-slide-group__wrapper > .v-slide-group__content {
			& > .v-tabs-slider-wrapper > .v-tabs-slider {
				border-radius: 8px;
				height: 0.5rem;
			}

			& > .v-tab {
				padding: 0 !important;
				margin-right: 1.5rem !important;
				text-transform: none !important;

				& > .v-badge {
					// font-family: Cabin;

					& > .v-badge__wrapper > .v-badge__badge {
						color: #575e60;
					}
				}
			}
		}
	}
}
</style>
