<template>
	<div v-if="!loaded || exhibitors.length" class="exhibitors-catalog__container">
		<template v-if="!showProgress && this.exhibitors.length > 0" style="width: 100%">
			<v-row class="row" no-gutters>
				<v-col v-for="(item, i) in exhibitors" :key="item.exhiid" md="3">
					<v-card class="mx-auto my-12 card" :outlined="true">
						<div style="position: relative">
							<div class="exhibitor-details-products__item-top-image" style="position: absolute">
								<router-link :to="{ name: 'ExhibitorDetails', params: { id: item.exhiid } }">
									<img
										class="exhibitor-details-products__item-top-image-img"
										v-if="item.imageurl"
										:src="item.imageurl"
										alt="photo"
									/>
									<img class="exhibitor-details-products__item-top-image-img" v-else :src="defaultPhoto" alt="photo" />
								</router-link>
							</div>
							<div style="position: absolute; margin: 0.625rem 0 0 0.625rem">
								<span v-if="item.typesid" class="exhibitor-details-products__item-top-image-PREMIUM">{{
									$t('exhibitors.premium')
								}}</span>
							</div>
							<div style="position: absolute; right: 0; margin-right: 0.625rem">
								<div class="exhibitor-details-products__item-top-image-buttons">
									<ShareDropdown :url="getShareURL(item.exhiid)" :exhibitorName="item.exhiname" white />
									<Modal :exhibitors="item" card interestFav />
								</div>
							</div>
						</div>
						<div class="bottom">
							<div class="text">
								<router-link :to="{ name: 'ExhibitorDetails', params: { id: item.exhiid } }" style="text-decoration: none">
									<v-card-title class="marginated titol">{{ item.exhiname }}</v-card-title>
								</router-link>

								<v-card-text class="marginated sectores">
									<!-- <div v-if="sectors[i] !== 'undefined'">{{ sectors[i] }}</div> -->
									<div v-if="item.sectors" :id="'row_' + i" @click="showMoreSectors(item.sectorsname, i)" v>
										{{ printSectors(item.sectorsname) }}
									</div>

									<!-- <div v-else class="exhibitors-catalog__body-row-sector-text marginated sectores"></div> -->
								</v-card-text>
							</div>
							<div>
								<v-card-actions class="actions">
									<v-img v-if="item.logourl" :src="item.logourl" class="imagen" alt="logo"></v-img>
									<v-img v-else :src="defaultPhoto" class="imagen" alt="logo"></v-img>

									<v-img :src="require('@/assets/images/paises/' + item.countryid + '.png')" alt="pais" class="countries"></v-img>
									<v-spacer></v-spacer>
									<div v-if="item.logourl" class="border">
										<img class="contact" :src="IconContact" alt="contact" />
									</div>
									<div v-else class="border">
										<img class="contact" :src="IconContact" alt="contact" />
									</div>
								</v-card-actions>
							</div>
						</div>
					</v-card>
				</v-col>
				<router-link :to="{ name: 'Exhibitors' }" class="interest-exhibitor-cards-search">
					<img class="interest-exhibitor-cards-search-img" :src="IconAdd" alt="add" />
					<div class="interest-exhibitor-cards-search-title">{{ $t('interests.searchExhibitors') }}</div>
				</router-link>
			</v-row>
		</template>
		<div v-else style="margin-left: 50%; margin-top: 5%; margin-bottom: 5%">
			<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
		</div>
	</div>
	<InterestEmpty v-else exhibitors />
</template>

<script>
import { getDocument } from '@/api/common.js';
import ShareDropdown from '@/components/common/ShareDropdown.vue';
import Modal from '@/components/common/Modal.vue';
import InterestEmpty from '@/components/interests/InterestEmpty.vue';

export default {
	name: 'InterestExhibitorCards',
	props: {
		exhibitors: {
			type: [Object, Array],
			required: true
		},
		interestExhibitors: []
	},

	data() {
		return {
			IconContact: require('@/assets/icons/contact-calendar.svg'),
			IconAdd: require('@/assets/icons/add.svg'),
			defaultPhoto: null,
			showProgress: false,
			favBtnProductSelected: false,
			loaded: false
		};
	},
	components: { ShareDropdown, Modal, InterestEmpty },
	created() {
		this.subscribeToEvents();
	},
	mounted() {
		this.getDefaultPhoto();
	},
	methods: {
		getCatalog: async function () {
			const data = this.splitSectors(this.exhibitors);
			this.exhibitors = data;
			this.loaded = true;
			// this.getIfProductIsFav();
		},

		splitSectors: function (data) {
			data.map((exhibitor) => {
				if (exhibitor.sectorsname && !Array.isArray(exhibitor.sectorsname)) {
					exhibitor.sectorsname = exhibitor.sectorsname.split(',');
				}
			});
			return data;
		},

		printSectors: function (inArray) {
			if (inArray.length > 2) {
				return inArray[0] + ', ' + inArray[1] + '...+' + (inArray.length - 2);
			} else {
				return inArray.join(', ');
			}
		},

		showMoreSectors: function (sectors, i) {
			const id = 'row_' + i;
			document.getElementById(id).innerHTML = sectors.join(', ');
		},
		getIfProductIsFav() {
			this.exhibitors.forEach((exhibitor) => {
				exhibitor.fav = false;
				// console.log(product);
				for (let x = 0; x < this.interestExhibitors.length; x++) {
					if (this.interestExhibitors[x].exhiid === exhibitor.exhibitor) {
						exhibitor.fav = true;
						this.favBtnProductSelected = true;
					}
				}
			});

			this.favBtnProductSelected = false;
		},

		getDefaultPhoto: async function () {
			const data = await getDocument('event', this.$store.state.eventInfo.evenid, 'event_app_primary');
			this.defaultPhoto = data[0].url;
		},

		subscribeToEvents() {
			this.$puiEvents.$on('searching-true', () => {
				this.showProgress = true;
			});
			this.$puiEvents.$on('searching-false', () => {
				this.showProgress = false;
			});
		},
		getShareURL: function (exhibitorId) {
			return window.location.origin + this.$router.resolve({ name: 'ExhibitorDetails', params: { id: exhibitorId } }).href;
		}
	},
	computed: {
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	},

	watch: {
		exhibitors: 'getCatalog'
	}
};
</script>


<style lang="scss" scoped>
.interest-exhibitor-cards-search {
	@media only screen and (max-width: 617px) {
		width: 100%;
	}
	@media only screen and (min-width: 617px) {
		width: calc(50% - 1.4em);
	}
	@media only screen and (min-width: 900px) {
		width: calc(33% - 1.4em);
	}
	@media only screen and (min-width: 960px) {
		width: calc(25% - 1.4em);
	}

	height: 18.75rem;
	border: 1px solid #d2d7d9;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	margin: 0.7em;

	&-title {
		padding-top: 1.25rem;
		display: table-caption;
		font-family: Cabin;
		font-weight: bold;
		font-size: 26px;
		text-align: center;
		word-spacing: 100vw;
		color: #293133;
	}
}

.contact {
	width: 18px;
	height: 20px;
}
.border {
	border: 1px solid #d2d7d9;
	box-sizing: border-box;
	border-radius: 8px;
	padding-bottom: 5px;
	padding-top: 10px;
	padding-left: 15px;
	padding-right: 15px;
	text-align: center;
}
.countries {
	margin-left: 12px;
	border-radius: 4px;
	max-height: 17.33px;
	max-width: 24px;
	min-height: 17.33px;
	min-width: 24px;
}
.bottom {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}
.text {
	padding-top: 9.5rem;
}
.router {
	text-decoration: none !important;
}

.card {
	border-radius: 8px;
	margin: 0.7em !important;
	display: flex;
	flex-direction: column;
}
.row {
	margin: 20px;
}

.marginated {
	margin-left: 4px;
}

.sectores {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	padding-top: 6px !important;
	/* identical to box height, or 150% */
	// min-height: 60px;
	// max-height: 60px;
	/* Neutral / Dark grey */

	color: #575e60;
}
.titol {
	font-family: Cabin;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	// min-height: 80px;
	// max-height: 80px;
	padding-bottom: 0px !important;

	/* Neutral / Extra-dark grey */

	color: #293133;
}

.imagen {
	max-width: 71px;
	max-height: 40px;
	width: auto;
	height: auto;
	object-fit: contain;
}
.actions {
	min-height: 55px;
	max-height: 55px;
	margin-left: 10px;
	margin-bottom: 10px;
	margin-right: 10px;
}

.exhibitors-catalog {
	background-color: #f3f5f5;
	width: 100%;
	padding: 2.5rem;
}

.exhibitors-catalog__container {
	background-color: #fff;
	padding-top: 2%;
	padding-left: 12%;
	padding-right: 12%;
	padding-bottom: 2%;
}

.products-catalog__body-row-name {
	&-PREMIUM {
		color: #fff;
		background-color: #d4af37;
		border-radius: 6px;
		padding: 0.125rem 0.375rem;
		font-weight: 500;
		font-size: 0.75rem;
	}
}
.exhibitors-catalog__pagination {
	display: flex;
	justify-content: center;
	align-items: baseline;

	&-info {
		margin-right: 0.5rem;
	}

	&-button {
		padding: 0.5rem 0.75rem;
		margin: 0.125rem;
		border-radius: 6px;
		background-color: #f5f7f7;
	}

	&-select {
		width: 4rem;
		margin: 0 0.25rem;
	}
}
.exhibitor-details-products__list {
	display: flex;
}

.exhibitor-details-products__item {
	border: 1px solid #d2d7d9;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0.313rem;
	width: 32%;
	height: 21rem;

	&-top {
		&-image {
			height: 9.5rem;
			width: 100%;

			&-img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 8px 8px 0 0;
			}

			&-PREMIUM {
				color: #fff;
				background-color: #d4af37;
				border-radius: 6px;
				padding: 0.125rem 0.375rem;

				height: 1.4rem;
				display: flex;
				align-items: center;
				font-family: Roboto;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 21px;
			}

			&-buttons {
				display: flex;
			}
		}

		&-text {
			padding: 10.5rem 0 1rem 1rem;
			padding-right: 1rem;

			&-title {
				font-family: Cabin;
				font-weight: bold;
				font-size: 16px;
				color: #293133;
				padding-bottom: 0.25rem;

				&-link {
					text-decoration: none;
				}
			}

			&-sector {
				font-weight: normal;
				font-size: 14px;
				color: #575e60;
			}
		}
	}

	&-bottom {
		width: 35%;
		& > img {
			width: 100%;
			max-height: 2.5rem;
			object-fit: cover;
			margin: 0 0 1rem 1rem;
		}
	}
}
</style>
